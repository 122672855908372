
import { defineComponent, ref } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
export default defineComponent({
  name: 'super-admin-ublock-batch-enrollment',
  components: {
    Field,
    Datatable,
  },
  data() {
    return {
      api_url: '',
      moment: '' as any,
      batch_extened_date: '',
      trainee: {
        entity_id: '',
        training_institute_id: '',
        tranche_id: '',
        course_info_id: '',
      },

      institutekey: 0,
      batchkey: 0,
      associations: [],
      institutes: [],
      tranches: [],
      courses: [],
      eligibleBatchList: [],
      checkeEligibleBatchList: [] as any,
      batch: [] as any,
      showSearchBtn: false,
      selectedBatch: [] as any,
      allSelected: false,
      showeligibleBatch: false,
      load: false,
      loading: false,
      bloading: false,
      tabIndex: ref(0),
      btnBlock: false,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    this.moment = moment;
  },
  methods: {
    async associationList() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    selectAll: function () {
      if (this.allSelected) {
        this.selectedBatch = [];
      } else {
        for (let i = 0; i < this.checkeEligibleBatchList.length; i++) {
          this.selectedBatch.push(this.checkeEligibleBatchList[i].id);
        }
      }
    },
    select: function () {
      this.allSelected = false;
    },

    async trainingInstitute() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (entity_id && this.trainee.tranche_id) {
        this.showSearchBtn = true;
      }
      await ApiService.get('institute/list?entity_id=' + entity_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getCourseList() {
      if (this.trainee.training_institute_id) {
        this.load = true;
        await ApiService.get(
          'course/list?entity_id=' +
            this.trainee.entity_id +
            '&tranche=' +
            this.trainee.tranche_id +
            '&institute_info_id=' +
            this.trainee.training_institute_id
        )
          .then((response) => {
            this.courses = response.data.data;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        this.trainee.course_info_id = '';
        this.courses = [];
      }
    },

    async getBatchList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (entity_id && this.trainee.tranche_id) {
        this.load = true;
        await ApiService.get(
          'configurations/super_admin/ineligible_batch?entity_id=' +
            entity_id +
            '&tranche_id=' +
            this.trainee.tranche_id +
            '&institute_info_id=' +
            institute_info_id +
            '&course_info_id=' +
            this.trainee.course_info_id
        )
          .then((response) => {
            this.eligibleBatchList = response.data.data;
            let bcheck = 0;
            for (let i = 0; i < this.eligibleBatchList.length; i++) {
              let tempObj = {
                batch_extened_date: '' as any,
              };
              if (response.data.data[i].batch_info.ext_enroll_date) {
                tempObj.batch_extened_date =
                  response.data.data[i]?.batch_info?.ext_enroll_date;
              } else {
                var dt = new Date(
                  response.data.data[i]?.batch_info?.start_date
                );

                if (
                  response.data.data[i]?.batch_info?.course_info
                    ?.course_config_info
                ) {
                  var grass_day =
                    response.data.data[i]?.batch_info?.course_info
                      ?.course_config_info?.e_grace;
                } else {
                  grass_day = 7;
                }
                dt.setDate(dt.getDate() + grass_day);
                tempObj.batch_extened_date = moment(dt).format('YYYY-MM-DD');
              }
              if (
                response.data.data[i].batch_info.is_eligible_for_enrollment == 1
              ) {
                bcheck = 1;
              }

              this.batch.push(tempObj);
              this.checkeEligibleBatchList.push(
                response.data.data[i]?.batch_info
              );
            }
            if (bcheck == 1) {
              this.btnBlock = true;
            } else {
              this.btnBlock = false;
            }

            this.institutekey += 1;
            this.load = false;
            this.showeligibleBatch = true;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error!',
          html: 'Please select required field.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    Allow(index, id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      let formData = new FormData();
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('batch_info_id', id);
      formData.set('is_eligible_for_enrollment', '1');
      formData.set('user_id', user_id);

      let batchObj = this.batch[index];
      for (var key in batchObj) {
        formData.set(key, batchObj[key]);
      }
      if (id) {
        Swal.fire({
          title: 'Are you sure you want to unblock this batch?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Allow!',
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post(
              'configurations/super_admin/unblock_batch_enroll',
              formData
            )
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.getBatchList();
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      }
    },

    Block(index, id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      let formData = new FormData();
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('entity_id', this.trainee.entity_id);
      formData.set('batch_info_id', id);
      formData.set('is_eligible_for_enrollment', '0');
      formData.set('user_id', user_id);

      let batchObj = this.batch[index];
      for (var key in batchObj) {
        formData.set(key, batchObj[key]);
      }
      if (id) {
        Swal.fire({
          title: 'Are you sure you want to block this batch?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Block!',
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post(
              'configurations/super_admin/unblock_batch_enroll',
              formData
            )
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.getBatchList();
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      }
    },

    bluckUnblock() {
      let data = {
        batch_ids: this.selectedBatch,
        batch_extened_date: this.batch_extened_date,
        is_eligible_for_enrollment: 1,
      };
      if (this.selectedBatch.length > 0 && this.batch_extened_date) {
        Swal.fire({
          title: 'Are you sure you want to unblock all selected batch?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Allow!',
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            ApiService.post(
              'configurations/super_admin/unblock_batch_enroll',
              data
            )
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.getBatchList();
                    this.loading = false;
                    this.selectedBatch = [];
                    this.allSelected = false;
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      } else {
        let msg = '';
        if (this.batch_extened_date) {
          msg = 'Please select a batch.';
        } else {
          msg = 'Please batch extened date required field.';
        }
        Swal.fire({
          title: 'Error!',
          html: msg,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    bluckBlock() {
      let data = {
        batch_ids: this.selectedBatch,
        batch_extened_date: this.batch_extened_date,
        is_eligible_for_enrollment: 0,
      };
      if (this.selectedBatch.length > 0) {
        Swal.fire({
          title: 'Are you sure you want to block all selected batch?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Block!',
        }).then((result) => {
          if (result.isConfirmed) {
            this.bloading = true;
            ApiService.post(
              'configurations/super_admin/unblock_batch_enroll',
              data
            )
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.getBatchList();
                    this.bloading = false;
                    this.selectedBatch = [];
                    this.allSelected = false;
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      } else {
        Swal.fire({
          title: 'Error!',
          html: 'Please select a batch.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
  },
});
